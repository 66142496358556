import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

class AdditionalItem extends Component {
    render() {
        const {
            decoration,
            decorationSelected,
            decorationPrice,
            plated,
            platedSelected,
            platedPrice,
            ruffles,
            sequinedSelected,
            rufflesPrice,
            rufflesSelected,
            OnAdditionalCheck,
            itemIndex,
            t
        } = this.props

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>{t('cart.sections.surcharge')}</strong>
                </ListGroupItem>
                {
                    decoration &&
                    <ListGroupItem className="item-button" active={decorationSelected || sequinedSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "decorationSelected")}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.withDeco')}</div>
                            <div className="small-price">
                                {formatNumber(decorationPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    plated &&
                    <ListGroupItem className="item-button" active={platedSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "platedSelected")}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.plated')}</div>
                            <div className="small-price">
                                {formatNumber(platedPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    ruffles &&
                    <ListGroupItem className="item-button" active={rufflesSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "rufflesSelected")}>
                        <div className="d-flex">
                            <div className="item-header">{t('cart.options.withRuffles')}</div>
                            <div className="small-price">
                                {formatNumber(rufflesPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {/* DON'T USE THIS ANYMORE, IT'S NOW THE SAME AS DECO
                    (decoration || sequined) &&
                    <ListGroupItem className="item-button" active={decorationSelected || sequinedSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "sequinedSelected")}>
                        <div className="d-flex">
                            <div className="item-header">{t('cart.options.withSequins')}</div>
                            <div className="small-price">
                                {formatNumber(sequinedPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                */}
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAdditionalCheck: (itemIndex, checkType) => dispatch(actions.additionalCheck(itemIndex, checkType))
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(AdditionalItem));