import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import { withTranslation } from 'react-i18next';

const TailoringItem = ({
    button,
    buttonSelected,
    buttonPrice,
    buttonQuantity,
    hem,
    hemSelected,
    hemQuantity,
    hemPrice,
    zipper,
    zipperSelected,
    zipperQuantity,
    zipperPrice,
    t
}) => (
    <ListGroup flush className="shopping-item-section">
        <ListGroupItem className="item-main-header">
            <strong>{t('cart.sections.tailoring')}</strong>
        </ListGroupItem>
        {
            buttonSelected &&
            <ListGroupItem className="item-button" active={buttonSelected}>
                <div className="d-flex">
                    <div className="item-header">{t('cart.options.button')}</div>
                    <div className="small-price">
                        {formatNumber(buttonPrice)}
                    </div>
                </div>
                {
                    buttonSelected &&
                    <div className="d-flex pt-1">
                        <div className="ml-auto">
                            {t('cart.quantity.label')}: {buttonQuantity}
                        </div>
                    </div>
                }
            </ListGroupItem>
        }
        {
            hemSelected &&
            <ListGroupItem className="item-button" active={hemSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.hem')}</div>
                    <div className="small-price">
                        {formatNumber(hemPrice)}
                    </div>
                </div>
                {
                    hemSelected &&
                    <div className="d-flex pt-1">
                        <div className="ml-auto">
                            {t('cart.quantity.label')}: {hemQuantity}
                        </div>
                    </div>
                }
            </ListGroupItem>
        }
        {
            zipperSelected &&
            <ListGroupItem className="item-button" active={zipperSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.zipper')}</div>
                    <div className="small-price">
                        {formatNumber(zipperPrice)}
                    </div>
                </div>
                {
                    zipperSelected &&
                    <div className="d-flex pt-1">
                        <div className="ml-auto">
                            {t('cart.quantity.label')}: {zipperQuantity}
                        </div>
                    </div>
                }
            </ListGroupItem>
        }
    </ListGroup>
)

export default withTranslation()(TailoringItem)