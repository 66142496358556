import React, { Component } from 'react'
import { ListGroup, ListGroupItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../Utils'
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'
import { speedyOptionDisabled } from '../../store/reducers/shoppingCart'

class LaundryItem extends Component {
    render() {
        const {
            OnNoLaundryCheck,
            OnWashingAndIroningCheck,
            OnWashingAndManualIroningCheck,
            OnWashingAndIroningSpeedyCheck,
            itemIndex,
            speedyOptionDisabled,
            t
        } = this.props

        const idNoSpeedy = `idNoSpeedy${itemIndex}`
        const idSpeedy = `idSpeedy${itemIndex}`
        const idManual = `idManual${itemIndex}`

        const {
            noLaundry,
            noLaundrySelected,
            noLaundryPrice,
            washingAndIroning,
            washingAndIroningSelected,
            washingAndIroningPrice,
            washingAndIroningSpeedy,
            washingAndIroningSpeedySelected,
            washingAndIroningSpeedyPrice,
            washingAndManualIroning,
            washingAndManualIroningSelected,
            washingAndManualIroningSpeedySelected,
            washingAndManualIroningPrice,
            cleaningAndRefreshing,
            cleaningNotWashing
        } = this.props

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>{t('cart.sections.laundry')}</strong>
                </ListGroupItem>
                {
                    washingAndIroning &&
                    <ListGroupItem
                        className="item-button single"
                        active={washingAndIroningSelected || washingAndIroningSpeedySelected}
                        onClick={() => {
                            if (!washingAndIroningSelected)
                                OnWashingAndIroningCheck(itemIndex)
                        }}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">
                                {cleaningNotWashing ? (washingAndManualIroningPrice  ? t('cart.options.cleanNotWashMachine') : t('cart.options.cleanNotWash')) : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : (washingAndManualIroningPrice ? t('cart.options.washAndIronMachine') : t('cart.options.washAndIron')))}
                                <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idNoSpeedy} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idNoSpeedy}>
                                    <PopoverHeader>{cleaningNotWashing ? (washingAndManualIroningPrice  ? t('cart.options.cleanNotWashMachine') : t('cart.options.cleanNotWash')) : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : (washingAndManualIroningPrice ? t('cart.options.washAndIronMachine') : t('cart.options.washAndIron')))}</PopoverHeader>
                                    <PopoverBody>{cleaningAndRefreshing ? 
                                        t('cart.laundry.cleanAndRefreshInfo') :
                                        (washingAndManualIroning ? t('cart.laundry.washAndMachineIronInfo') : t('cart.laundry.washAndIronInfo'))}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="small-price">
                                {formatNumber(washingAndIroningPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    washingAndManualIroning &&
                    <ListGroupItem
                        className="item-button single"
                        active={washingAndManualIroningSelected || washingAndManualIroningSpeedySelected}
                        onClick={() => {
                            if (!washingAndManualIroningSelected)
                                OnWashingAndManualIroningCheck(itemIndex)
                        }}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">
                                {cleaningNotWashing ? t('cart.options.cleanNotWashManual') : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : t('cart.options.washAndManualIron'))}
                                <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idManual} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idManual}>
                                    <PopoverHeader>{cleaningNotWashing ? t('cart.options.cleanNotWashManual') : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : t('cart.options.washAndManualIron'))}</PopoverHeader>
                                    <PopoverBody>{cleaningAndRefreshing ? 
                                        t('cart.laundry.cleanAndRefreshInfo') :
                                        t('cart.laundry.washAndManualIronInfo')}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="small-price">
                                {formatNumber(washingAndManualIroningPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                  /*  washingAndIroningSpeedy &&
                    <ListGroupItem
                        className="item-button single"
                        disabled={speedyOptionDisabled}
                        active={washingAndIroningSpeedySelected}
                        onClick={() => OnWashingAndIroningSpeedyCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.washAndIronExpress')}
                            <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idSpeedy} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idSpeedy}>
                                    <PopoverHeader>{t('cart.options.washAndIronExpress')}</PopoverHeader>
                                    <PopoverBody>{t('cart.laundry.expressInfo')}</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="small-price">
                                <span>{formatNumber(washingAndIroningSpeedyPrice + washingAndIroningPrice)}</span>
                            </div>
                        </div>
                    </ListGroupItem>*/
                }
                {
                    noLaundry &&
                    <ListGroupItem
                        className="item-button single"
                        active={noLaundrySelected}
                        onClick={() => OnNoLaundryCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.noLaundry')}
                            </div>
                            {
                                noLaundryPrice === 0 &&
                                <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                            }
                            {
                                noLaundryPrice > 0 &&
                                <div className="small-price">
                                    {formatNumber(noLaundryPrice)}
                                </div>
                            }
                        </div>
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        speedyOptionDisabled: speedyOptionDisabled(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnNoLaundryCheck: (itemIndex) => dispatch(actions.noLaundryCheck(itemIndex)),
        OnWashingAndIroningCheck: (itemIndex) => dispatch(actions.washingAndIroningCheck(itemIndex)),
        OnWashingAndManualIroningCheck: (itemIndex) => dispatch(actions.washingAndManualIroningCheck(itemIndex)),
        OnWashingAndIroningSpeedyCheck: (itemIndex) => dispatch(actions.washingAndIroningSpeedyCheck(itemIndex)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LaundryItem));