import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import { withTranslation } from 'react-i18next';

const LaundryItem = ({
    noLaundrySelected,
    noLaundryPrice,
    washingAndIroningSelected,
    washingAndIroningPrice,
    washingAndIroningSpeedySelected,
    washingAndIroningSpeedyPrice,
    washingAndManualIroningPrice,
    washingAndManualIroningSelected,
    cleaningAndRefreshing,
    cleaningNotWashing,
    t
}) => {
    return (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>{t('cart.sections.laundry')}</strong>
            </ListGroupItem>
            {
                washingAndIroningSelected &&
                <ListGroupItem
                    className="item-button single"
                    active={washingAndIroningSelected}
                >
                    <div className="d-flex item-header-font">
                        <div className="item-header">
                        {cleaningNotWashing ? (washingAndManualIroningPrice  ? t('cart.options.cleanNotWashMachine') : t('cart.options.cleanNotWash')) : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : (washingAndManualIroningPrice ? t('cart.options.washAndIronMachine') : t('cart.options.washAndIron')))}
                        </div>
                        <div className="small-price">
                            {formatNumber(washingAndIroningPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                washingAndManualIroningSelected &&
                <ListGroupItem
                    className="item-button single"
                    active={washingAndManualIroningSelected}
                >
                    <div className="d-flex item-header-font">
                        <div className="item-header">
                        {cleaningNotWashing ? t('cart.options.cleanNotWashManual') : (cleaningAndRefreshing ? t('cart.options.cleanAndRefresh') : t('cart.options.washAndManualIron'))}
                        </div>
                        <div className="small-price">
                            {formatNumber(washingAndManualIroningPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                washingAndIroningSpeedySelected &&
                <ListGroupItem action className="item-button" active={washingAndIroningSpeedySelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">{t('cart.options.washAndIronExpress')}</div>
                        <div className="small-price">
                            <span>{formatNumber(washingAndIroningSpeedyPrice + washingAndIroningPrice)}</span>
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                noLaundrySelected &&
                <ListGroupItem className="item-button single" active={noLaundrySelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">{t('cart.options.noLaundry')}</div>
                        {
                            noLaundryPrice === 0 &&
                            <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                        }
                        {
                            noLaundryPrice > 0 &&
                            <div className="small-price">
                                {formatNumber(noLaundryPrice)}
                            </div>
                        }
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )
}

export default withTranslation()(LaundryItem)