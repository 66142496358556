export const deTranslations = {
  general: {
    siteTitle: 'Deine Online Wäscherei & Schneiderei | WÖS.CH',
  },
  "home": {
    "metaTitle": "Deine Online Wäscherei & Schneiderei | WÖS.CH",
    "metaDescription": "WÖS.CH ist Dein online Wäscherei-, Textilreinigungs- und Schneiderei-Service in Zürich – Wir holen Deine Wäsche innert 24h ab & liefern sie Dir wieder – wann immer Du willst.",
    "heroImageAlt": "WÖS.CH Hero Image",
    "logoAlt": "WÖS.CH Logo",
    "heroTitle": "Sammle Momente",
    "heroSubtitle": "(statt schmutzige Wäsche)",
    "heroText1": "Dein online Wäscherei-, Textilreinigungs- und Schneiderei-Service in Zürich.",
    "heroText2": "Abholung und Lieferung innert 24 Stunden, wann immer Du willst.",
    "ctaBasket": "Wäschekorb füllen",
    "ctaHowItWorks": "So geht's",

    "howItWorksTitle": "So einfach geht's",
    "howItWorksStep1Title": "Auftrag",
    "howItWorksStep1Text": "Gib Deinen Auftrag auf, wo immer Du auch bist. Entscheide Du über den Tag und die Uhrzeit von Abholung und Lieferung. Sichere Zahlungen mit allen gängigen Zahlungsmitteln wie Twint & Kreditkarte.",
    "howItWorksStep2Title": "Abholung",
    "howItWorksStep2Text": "Abholung, wann immer Du willst. Stelle Deine Wäsche und Accessoires zusammen. Dein Butler ist stets zur Stelle.",
    "howItWorksStep3Title": "Service",
    "howItWorksStep3Text": "Wir pflegen Deine hochwertige Wäsche, Kleidung und Accessoires mit umweltfreundlichen, qualitativ hochwertigen und individuell zugeschnittenen Prozessen.",
    "howItWorksStep4Title": "Lieferung",
    "howItWorksStep4Text": "Lieferung, wann immer Du willst. Wir haben immer ein offenes Ohr für Deine Wünsche und freuen uns auf Dein Feedback.",
    "showProducts": "Sortiment anzeigen",

    "whyTitle": "Warum wös.ch?",
    "whySubtitle": "wös.ch ist menschlich und gleichzeitig extrem digital.",
    "whyImageAlt": "Warum WÖS.CH Illustration",
    "whyText1": "Dein digitaler Butler ist zum Greifen nah und bietet Dir ein einzigartiges und emotionales Service-Erlebnis mit starker menschlicher Seite rund um Deine Person: Alles dreht sich um Dich! Wir schenken Dir die Zeit für die schönen Dinge im Leben. Denn wir kümmern uns um Deine Kleidung und Deine Accessoires.",
    "whyText2": "Lass Dich von uns verwöhnen:",
    "whyText3": "Mit einem einfachen Klick sagst Du uns, «was wir wann und wo tun sollen». Wir lassen Deiner Kleidung und Deinen Accessoires eine «massgeschneiderte Pflege» zukommen, denn sie liegen uns genauso sehr am Herzen wie Dir. Schweizer Handwerkskunst mit Flair und Auge fürs Detail. wös.ch ist ein Service von Menschen für Menschen, bei dem zwar die Technologie als Instrument der Erleichterung eingesetzt wird, aber niemals die Dynamiken des Zuhörens und der zwischenmenschlichen Beziehung ersetzt: Der menschliche Touch bleibt der Schlüssel für unser einzigartiges, hervorragendes und individuelles Service-Angebot.",

    "serviceTitle": "Unvergleichlicher Service wann immer du willst",
    "serviceDescription": "Wir bieten Dir einen ökologisch nachhaltigen, individuellen Qualitäts-Service mit Auge fürs Detail. Du erteilst den Auftrag wann und wo Du willst, zu Hause, unterwegs, aus dem Büro oder in der Wäscherei. Abholung und Lieferung von 7.00 bis 19.00 Uhr, sechs Tage die Woche, wann Du willst. Auftragsverfolgung in Echtzeit. Sichere Zahlungen. Der Concierge Deines Vertrauens!",

    "features": {
      "quality": {
        "title": "Qualität",
        "text": "Wir achten auf jedes noch so kleine Detail, um Dir den bestmöglichen individuellen Service zu bieten."
      },
      "green": {
        "title": "Green",
        "text": "Ökologische Nachhaltigkeit durch den Einsatz von 100% erneuerbaren Energien, biologisch abbaubaren Waschmitteln und recyclingfähigen Verpackungen."
      },
      "smart": {
        "title": "Smart",
        "text": "Unser Service ist stets mobil verfügbar, egal wo Du auch bist, ob zu Hause, im Büro oder im Gym."
      },
      "hours24": {
        "title": "24 Stunden",
        "text": "Abholung und Lieferung in 24 Stunden an sechs Tagen in der Woche zur Zeit Deiner Wahl. Unser Rundum-sorglos-Service für Dich!"
      }
    },

    "history": {
      "title": "Unsere Geschichte",
      "text": "wös.ch ist ein Online-Projekt für Dienstleistungen rund um die Person, wie Wäscherei und Änderungs- und Reparaturschneiderei, das 2018 gestartet wurde. Zuerst waren wir auf der Suche nach jemanden, der sich zuverlässig, einfach und termingerecht um unsere Wäsche und Accessoires kümmert, und heute tun wir dasselbe mit viel Liebe für Dich. Dabei bieten wir Dir stets die beste Qualität im Einklang mit Deinen Werten und Ansprüchen. Unser Service hat eine starke menschliche und soziale Seite: handwerkliche Arbeit unter Beachtung der kleinsten Details, Einsatz biologisch abbaubarer Waschmittel und Materialien, Lieferung mit umweltfreundlichen Transportmitteln, individueller Conciergerie-Service und vieles mehr. Unsere ständige Zusammenarbeit mit unseren sorgfältig ausgewählten Partnern und Lieferanten ermöglicht es uns, hohe Standards, einen individuellen Service und die Beachtung jedes noch so kleinen Details zu garantieren. Unsere Mission bei wös.ch besteht darin, ein einzigartiges Service-Erlebnis zu schaffen, in dem die Leidenschaft, die Sorge um den Menschen, das Achten auf Qualität und der Blick fürs Detail zur Geltung kommen."
    },

    "values": {
      "title": "Unsere Werte",
      "description": "Unsere Werte stehen für unsere Kultur, unsere Einstellung und unsere Hingabe an das was wir tun. Diese Werte helfen uns, stets zu sein, was wir wollen, und die richtigen Entscheidungen zu treffen: Von der Wäsche, die wir waschen, über die Handhabung der Annahme und Ausgabe bis hin zum Online-Erlebnis, dass wir unseren Kunden bieten.",
      "valuesImageAlt": "Unsere Werte Illustration",
      "value1": {
        "title": "Dinge und Lösungen",
        "text": "mit Mehrwert für alle schaffen: für den Kunden, für das Unternehmen, für die Allgemeinheit"
      },
      "value2": {
        "title": "nachhaltig Denken,",
        "text": "Schaffen, Managen und Entscheiden für dauerhafte Nachhaltigkeit bei allem was wir tun"
      },
      "value3": {
        "title": "menschlich bleiben",
        "text": "und stets authentische, individuelle persönliche Beziehungen entwickeln und pflegen"
      },
      "value4": {
        "title": "ein Zeichen für solide Arbeit setzen,",
        "text": "kreative und intelligente Lösungen erdenken, sich stets selbst übertreffen"
      }
    }
  },
  "header": {
    "logoAlt": "WÖS.CH Logo",
    "nav": {
      "howItWorks": "So geht's",
      "services": "Service",
      "prices": "Preise",
      "forCompanies": "Für Firmen",
      "ecoFriendly": "Eco-Friendly",
      "contact": "Kontakt"
    },
    "products": "Sortiment",
    "showProducts": "Sortiment anzeigen",
    "account": {
      "yourAccount": "Dein Account",
      "viewProfile": "Siehe Profil",
      "adminOrders": "Admin - Orders",
      "adminUsers": "Admin - Users",
      "logout": "Logout"
    },
    "cart": {
      "laundryBasket": "Wäschekorb"
    }
  },
  "footer": {
    "logoAlt": "WÖS.CH Logo",
    "links": {
      "terms": "AGB",
      "privacy": "Datenschutz",
      "imprint": "Impressum",
      "faq": "FAQ"
    },
    "hours": {
      "title": "Öffnungszeiten",
      "weekdays": "Montag — Freitag",
      "weekdayHours": "06.00 — 18.00",
      "saturday": "Samstag",
      "saturdayHours": "09.00 — 15.00",
      "online": "www.wös.ch",
      "onlineHours": "24/7"
    },
    "payments": {
      "mastercard": "mastercard",
      "visa": "visa",
      "postfinance": "postfinance",
      "twint": "twint",
      "paypal": "paypal",
      "invoice": "rechnung",
      "prepaidCard": "prepaid_card",
      "giftCard": "gift_card"
    }
  },
  services: {
    meta: {
        title: "Dieser Service erwartet Dich | WÖS.CH",
        description: "Schneller Wäscherei- & Schneiderei-Service in einem, für Dich erdacht. Hier erfährst Du, welche Services Dir zur Verfügung stehen.",
        image: "https://www.wös.ch/images/buegeleisen.png",
        url: "https://www.wös.ch/services"
    },
    hero: {
        title: "dein butler",
        text1: "Klingle nach dem Butler, wann und wo immer Du willst.",
        text2: "Schneller Wäscherei- und Schneiderei-Service in einem, für Dich erdacht.",
        text3: "Rufe Deinen Butler per Smartphone, Tablet oder Computer.",
        imageAlt: "Dein Butler Illustration"
    },
    laundry: {
        title: "Wäscherei",
        subtitle: "Umweltfreundliche Pflege",
        textHeader: "Wir lieben Deine Kleidung genau wie Du.",
        text: "Wir pflegen Deine Wäsche sorgsam mit biologisch abbaubaren Waschmitteln, erneuerbaren Energien und recyclingfähigen Verpackungen. Eine umweltfreundliche Entscheidung für Deine Haut und unsere Welt!",
        imageAlt: "Wäscherei Service"
    },
    ironing: {
        title: "Bügelservice",
        subtitle: "Faltenfreie Perfektion",
        textHeader: "Bei uns ist der Luxus mit Händen spürbar.",
        text: "Tadellos gebügelt mit höchster Qualität und Blick für jedes noch so kleine Detail — damit garantieren wir ein einzigartiges und hochwertiges Ergebnis. Erlebe die Schönheit der Perfektion!",
        imageAlt: "Bügelservice"
    },
    tailoring: {
        title: "Schneiderei",
        subtitle: "Präzision bis ins kleinste Detail",
        textHeader: "Wir arbeiten mit Blick für jedes Detail.",
        text: "Perfekte Anpassung Deiner Wäsche durch Schneiderarbeiten mit hoher Präzision. Säume, Reissverschlüsse, Nähte, Sticken von Monogrammen und Ersetzen von Knöpfen. Der feine Unterschied liegt im Detail!",
        imageAlt: "Schneiderei Service"
    },
    boxes: {
        simple: {
            title: "einfach",
            text: "Mit ein paar Klicks per Smartphone, Tablet oder Computer, wo immer Du bist! Um den Rest kümmern uns wir.",
            image: "smart",
            imageAlt: "Einfach Icon"
        },
        comfortable: {
            title: "bequem",
            text: "Abholung und Lieferung in Zürich innerhalb von 24 Stunden. Wo und wann immer Du willst.",
            image: "bequem",
            imageAlt: "Bequem Icon"
        },
        topPrice: {
            title: "top preis",
            text: "Wir garantieren Dir ein hervorragendes Preis-Leistungsverhältnis.",
            image: "preise-leistungs",
            imageAlt: "Top Preis Icon"
        },
        helpful: {
            title: "hilfsbereit",
            text: "Wir freuen uns auf Dein Feedback, Deine Anregungen und Wünsche. Dir den besten Service zu bieten, dafür sind wir da!",
            image: "hilfsbereit",
            imageAlt: "Hilfsbereit Icon"
        }
    }
  },
  forCompanies: {
    metaTitle: 'Der Wäscheservice für Ihr Unternehmen | WÖS.CH',
    metaDescription: 'Extrem günstige Bedingungen Für Unternehmen – Ein Concierge-Service, der 7 Tage die Woche rund um die Uhr verfügbar ist, ohne zusätzliche Kosten.',
    title: 'wös.ch für unternehmen',
    subtitle: 'Wir bieten Ihren Mitarbeitenden einen Concierge-Service, der 7 Tage die Woche rund um die Uhr perSmartphone, Tablet oder Computer verfügbar ist.',
    description1: 'Vereinbarungen mit extrem günstigen Bedingungen, mit Abholung und Lieferung direkt im Büro oder zu Hause.',
    description2: 'Dabei entstehen für das Unternehmen keine zusätzlichen Kosten.',
    requestAgreement: 'Eine Vereinbarung beantragen',
    emailPlaceholder: 'E-Mail*',
    send: 'SENDEN',
    sending: 'SENDEN...',
    sent: 'GESCHICKT',
    invalid: 'UNGÜLTIG',
    error: 'ERROR'
  },
  ecoFriendly: {
    metaTitle: 'Unsere Wäscherei ist Eco-Friendly | WÖS.CH',
    metaDescription: 'Von biologisch abbaubaren Waschmitteln & Verpackungen bis hin zur Arbeit mit erneuerbarer Energie – WÖS.CH ist Eco-Friendly!',
    title: 'Unsere wäscherei ist eco-friendly!',
    subtitle: 'Eine wichtige Entscheidung für Deine Haut und unsere Welt!',
    description1: 'Wir arbeiten umweltfreundlich. Denn wir benützen biologisch abbaubare Waschmittel und achten auf die Umwelt. Bei allem was wir tun.',
    description2: 'Deine Wäsche und Kleidung haben bei uns höchste Priorität, wir pflegen sie mit viel Liebe und achten dabei auf die Welt, in der wir leben. Da wir stets mit Umsicht handeln, ist es uns gelungen, alle unsere Tätigkeiten umweltgerecht zu gestalten.',
    description3: 'In unserer umweltfreundlichen Wäscherei steht Ihr, Du, Deine Kleidung und die Umwelt, im Mittelpunkt. Wir pflegen Deine Wäsche sorgsam, mit erneuerbaren Energien und biologisch abbaubaren Waschmitteln und Verpackungen.',
    description4: 'Denn nur die Achtung des gesamten Ökosystems ermöglicht es uns, zu lernen, nachhaltig zu leben, ohne das Gleichgewicht der Natur zu stören. Deshalb sollte jeder Mensch sich für eine gesündere Umwelt verantwortlich fühlen.',
    description5: 'Aus genau diesem Grund möchten wir von wös.ch mit gutem Beispiel vorangehen und uns am Modell der natürlichen Systeme orientieren, die in der Lage sind, sich effizient selbst zu regulieren.'
  },
  contact: {
    metaTitle: 'Noch Fragen? Kontaktiere uns | WÖS.CH',
    metaDescription: 'Du hast noch Fragen oder bist einfach nur neugierig? Kontaktiere uns jetzt per Mail oder Telefon.',
    title: 'Hello!',
    description: 'Wenn Du Fragen hast oder einfach nur neugierig bist und mehr erfahren möchtest,\nannst Du uns gerne unter info@woes.ch schreiben oder über die Nummer 044 304 22 98 anrufen.\nOder besuche einfach unsere sozialen Kanäle.',
    form: {
      title: 'Angebot anfordern',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail-Adresse',
      phone: 'Telefon',
      selectService: 'Service auswählen',
      comment: 'Kommentar',
      requestQuote: 'Angebot anfordern',
      sending: 'Senden...',
      sent: 'erfolgreich gesendet',
      services: {
        laundry: 'Wäscherei',
        tailoring: 'Schneiderei',
        other: 'Anderes'
      },
      errors: {
        lastNameRequired: 'Der Nachname ist erforderlich',
        emailRequired: 'Die E-Mail ist erforderlich',
        invalidEmail: 'Nicht gültige E-Mail-Adresse',
        nameRequired: 'Der Name ist erforderlich',
        messageRequired: 'Der Kommentar ist erforderlich',
        submitError: 'Etwas ist schief gelaufen, bitte versuchen Sie es später'
      }
    }
  },
  products: {
    metaTitle: 'Ob Hemden oder Alltagswäsche - Erstelle Deinen Auftrag | WÖS.CH',
    metaDescription: 'In unserer Shop-Übersicht kannst Du ganz einfach mit Zig-verschiedenen Kleidungsstücken und Textilien Deinen persönlichen Auftrag zusammenstellen.',
    title: 'Wähle deine Wäsche',
    helpText: 'Hier findest du eine Liste mit allen unseren waschbaren Textilien. Nach dem Hinzufügen kannst du jeweils einstellen, wie du deine Textilie genau behandelt haben willst – Wäscherei, Reinigung und Schneiderei. Hat es bei einem Artikel mehrere Varianten, so kannst du nach dem Hinzufügen noch dein Material wählen, so dass wir deine Wäsche bestmöglich behandeln können.',
    showFullHelp: 'Ganze Erklärung anzeigen',
    searchPlaceholder: 'Suche...',
    search: 'Suche',
    reset: 'zurücksetzen',
    proceedToCart: 'Weiter zum Wäschekorb'
  },
  productCard: {
    addToCart: 'In den Wäschekorb',
    addedToCart: 'Zum Wäschekorb hinzugefügt',
    configure: 'Konfigurieren',
    productImage: 'Produkt',
    variants: '{{count}} Varianten'
  },
  categories: {
    "all": "Alle",
    "clothing": "Bekleidung",
    "outdoor-sports": "Outdoor / Sport",
    "festive-wear": "Festkleidung",
    "accessories": "Accessoires",
    "home-textiles": "Heimtextilien",
    "leather": "Leder",
    "curtain-cleaning": "Vorhangreinigung",
    "pets": "Haustiere",
    "working-clothes": "Berufsbekleidung",
    "childrens-clothing": "Kinderbekleidung 0-10 Jahre",
    "gift-card": "Gift Card"
  },
  cart: {
    overlay: {
      productAdded: "Produkt hinzugefügt.",
      configureProduct: "Hier kannst du deinen Artikel konfigurieren.",
      configureTitle: "Produkt konfigurieren",
      configureSubtitle: "Wähle deinen gewünschten Service",
      addMore: "Weitere Artikel hinzufügen",
      toBasket: "Weiter zum Wäschekorb",
      addToBasket: "Hinzufügen zum Wäschekorb"
    },
    notes: {
      title: "Hinweis",
      cancel: "ABBRECHEN",
      confirm: "BESTÄTIGEN"
    },
    perKilo: {
      title: "Beinhaltet nur folgende Artikel",
      content: "Badetücher/Frotteewäsche, Bademäntel, Geschirr- und Küchenhandtücher, Unterhemden, Unterwäsche, Büstenhalter, Socken, Strumpfhosen, Leggings, Badebekleidung, Bodies, Pyjamas (Baumwolle), Trainingsacken, Trainingshosen"
    },
    title: 'Wäschekorb',
    subtotal: 'Zwischentotal',
    emptyCart: 'Dein Warenkorb ist noch leer, füge jetzt die Artikel für Deinen Auftrag hinzu!',
    addMoreItems: 'Weitere Artikel hinzufügen',
    proceedToDelivery: 'Abholung und Lieferung wählen',
    proceedToPayment: 'Weiter zur Zahlung',
    sections: {
      laundry: 'Wäscherei',
      tailoring: 'Schneiderei',
      delivery: 'Lieferung',
      variant: 'Variante',
      surcharge: 'Zuschlag'
    },
    options: {
      noLaundry: 'Nur Schneiderei',
      noLaundryTitle: 'Schneiderei',
      washAndIron: 'Waschen & Bügeln',
      washAndManualIron: 'Waschen & von Hand bügeln',
      washAndIronMachine: 'Waschen & maschinell bügeln',
      washAndIronExpress: 'Waschen & Bügeln Express',
      cleanAndRefresh: 'REINIGEN UND AUFFRISCHEN',
      cleanNotWash: 'Reinigen & Bügeln',
      cleanNotWashManual: 'Reinigen & von Hand Bügeln',
      cleanNotWashMachine: 'Reinigen & maschinell Bügeln',
      button: 'Knopf',
      hem: 'Kürzen',
      zipper: 'Reissverschluss',
      folded: 'Gefaltet',
      onHanger: 'auf Bügel',
      withDeco: 'MIT STRASS, PAILETTEN, DEKO',
      withRuffles: 'MIT RÜSCHEN, FALTEN BÜGELN',
      withSequins: 'MIT STRASS, PAILETTEN',
      napa: 'NAPPA - UND NAPPALAN',
      velour: 'VELOUR',
      plated: 'mit Plissée',
      washOnly: 'Waschen, nicht gebügelt'
    },
    quantity: {
      label: 'Menge',
      m2: 'm2',
      noScale: {
        title: 'Keine Waage zur Hand?',
        description: 'Kein Problem! Wir wiegen deine Wäsche und berechnen dir nur das effektive Gewicht. War deine Schätzung zu hoch, so kriegst du Geld zurück – war sie zu tief, kannst du den fehlenden Betrag bequem nachzahlen.'
      }
    },
    totals: {
      orderAmount: 'Auftragssumme',
      shippingCosts: 'Versandkosten',
      shippingNote: 'Abholung und Lieferung für Aufträge über 30.00 CHF gratis',
      totalWithShipping: 'Summe inkl. Zustellungskosten',
      usedCredits: 'Verwendetes Guthaben und Bons',
      amountToPay: 'Zu zahlender Betrag',
      remainingCredit: 'Verbleibendes Guthaben',
      finalAmount: 'Zu zahlender Betrag'
    },
    bonus: {
      title: 'Bon, Gift Card, Prepaid-Karte',
      placeholder: 'Code eingeben',
      confirm: 'BESTÄTIGEN',
      sending: 'SENDEN...',
      invalid: 'Code ungültig'
    },
    promo: {
      title: 'Promo-Code',
      placeholder: 'Hast Du einen Promo-Code bekommen? Bitte hier eingeben',
      alreadyUsed: 'Du hast den Gutscheincode bereits verwendet',
      invalid: 'Promo-Code ungültig'
    },
    checkout: {
      pickup: {
        title: 'Abholung',
        inStore: 'Im Laden vorbeibringen',
        chooseDate: 'Datum wählen',
        chooseTime: 'Zeit wählen'
      },
      delivery: {
        title: 'Lieferung',
        inStore: 'Im Laden abholen',
        chooseDate: 'Datum wählen',
        chooseTime: 'Zeit wählen'
      },
      payment: {
        title: 'Zahlung',
        confirm: 'BESTÄTIGEN UND ZAHLEN',
        processing: 'Verarbeitung...',
        success: 'Erfolg!',
        error: 'Fehler bei der Zahlung'
      },
      dateTime: {
        title: 'Datum und Uhrzeit Abholung',
        close: 'Schliessen',
        morning: 'Morgen',
        afternoon: 'Nachmittag',
        evening: 'Abend'
      },
      summary: {
        title: 'Auftragszusammenfassung',
        items: 'Artikel',
        total: 'Gesamt'
      },
      steps: {
        cart: 'Wäschekorb',
        pickup: 'Abholung',
        delivery: 'Lieferung',
        payment: 'Zahlung'
      },
      address: {
        info: 'Info: vor Festlegung der Lieferung bitte Abholung bestätigen!',
        confirm: 'BESTÄTIGEN',
        change: 'ÄNDERN',
        edit: 'Bearbeiten',
        title: 'Adresse bearbeiten',
        pickupLocation: 'Abholort (z.B. Büro, Haus..)',
        locationPlaceholder: 'Adresse',
        street: 'Strasse/Platz und Hausnummer',
        streetPlaceholder: 'Strasse',
        phone: 'Telefon',
        phonePlaceholder: 'Telefon',
        city: 'Stadt',
        cityPlaceholder: 'Stadt',
        note: 'Hinweis',
        notePlaceholder: 'Hinweis',
        date: 'Datum',
        from: 'Von',
        to: 'Bis',
        submit: 'BESTÄTIGEN',
        cancel: 'ABBRECHEN',
        validation: {
          required: 'Dieses Feld ist erforderlich',
          invalidPhone: 'Ungültige Telefonnummer',
          invalidPostcode: 'Ungültige Postleitzahl'
        }
      }
    },
    removeItem: 'Artikel entfernen',
    free: 'Gratis',
    delivery: {
      differentTimes: 'Dein Warenkorb enthält Artikel, die unterschiedliche Verarbeitungszeiten haben könnten.\nBevorzugst Du eine gemeinsame oder zwei getrennte Lieferungen?',
      combined: 'Gemeinsame Lieferung',
      separate: 'Getrennte Lieferung',
      itemCount: 'Lieferung: {{count}} Stück'
    },
    giftCard: {
      title: 'Schenke deinen Liebsten die Dienstleistungen der wös.ch!',
      recipientName: 'Vor- und Nachname der Person, die das Geschenk erhalten wird',
      email: 'E-Mail',
      emailCheck: 'E-Mail wiederholen',
      message: 'Persönliche Nachricht',
      instructions: 'Fülle das Formular mit dem Namen und der E-Mail Adresse der Person aus, die das Geschenk erhalten wird und gebe eine persönliche Nachricht ein.',
      confirm: 'Bestätigen',
      change: 'Ändern',
      errors: {
        nameRequired: 'Der Vor- und Nachname ist erforderlich',
        emailRequired: 'Die E-Mail ist erforderlich',
        invalidEmail: 'Nicht gültige E-Mail-Adresse',
        emailCheckRequired: 'Wiederholtes E-Mail ist erforderlich',
        emailMismatch: 'Die eingegebenen E-Mail stimmen nicht überein',
        messageRequired: 'Der Kommentar ist erforderlich'
      }
    },
    laundry: {
      cleanAndRefreshInfo: 'Mit unseren innovativen Tiefenreinigungsverfahren werden Ihre Pelze bestmöglich geschont und die Form dieser edlen Kleidungsstücke wird bewahrt. Ganz ohne Rückstände, Reizstoffe und Gerüche.',
      washAndIronInfo: 'Mit professionellem Bügelservice für ein perfektes, knitterfreies Ergebnis.',
      washAndManualIronInfo: 'Von Hand gebügelt für ein perfektes, knitterfreies Bügel-Ergebnis.',
      washAndMachineIronInfo: 'Mit professionellem, maschinellem Bügel-Service.',
      expressInfo: 'Abholung & Lieferung deiner Kleider innerhalb eines Tages für zusätzlcihe CHF 5.00 pro Artikel. Angebot gültig nur für die Stadt Zürich.',
      noLaundryInfo: 'Wir bieten dir hochpräzise Schneiderarbeiten an. Kürzen, Reissverschluss und Knopfersatz. Es können nur gereinigte Kleider für die Schneiderei abgegeben werden'
    },
    steps: {
      cart: 'Wäschekorb',
      pickup: 'Abholung',
      delivery: 'Lieferung',
      payment: 'Zahlung'
    },
  },
  howItWorks: {
    meta: {
      title: 'So funktioniert der Wäscheservice | WÖS.CH',
      description: 'In vier einfachen Schritten gelangst Du von der Auftragserteilung bis zur Lieferung Deiner frisch gewaschenen & bei Bedarf geschneiderten Wäsche.'
    },
    header: {
      title: 'Rundum sorglos',
      description: 'Wäscherei- und Schneiderei-Service in einem, für Dich erdacht.\nAnnahme und Ausgabe, wann immer Du willst.\nQualität, Komfort, Eleganz. Wir schenken Dir eine Art zu leben.'
    },
    tryNow: 'JETZT PROBIEREN !',
    steps: {
      order: {
        title: 'Auftrag',
        description: 'Gib Deinen Auftrag auf, wo immer Du auch bist. Entscheide Du über den Tag und die Uhrzeit von Abholung und Lieferung. Sichere Zahlungen mit allen gängigen Zahlungsmitteln wie Twint & Kreditkarte.'
      },
      pickup: {
        title: 'Abholung',
        description: 'Abholung, wann immer Du willst. Stelle Deine Wäsche und Accessoires zusammen. Dein Butler ist stets zur Stelle.'
      },
      service: {
        title: 'Service',
        description: 'Wir pflegen Deine hochwertige Wäsche, Kleidung und Accessoires mit umweltfreundlichen, qualitativ hochwertigen und individuell zugeschnittenen Prozessen.'
      },
      delivery: {
        title: 'Lieferung',
        description: 'Lieferung, wann immer Du willst. Wir haben immer ein offenes Ohr für Deine Wünsche und freuen uns auf Dein Feedback.'
      }
    }
  },
  prices: {
    meta: {
      title: 'Preise | WÖS.CH',
      description: 'Faire Preise für hochwertige Dienstleistungen. Wäscherei und Schneiderei in einem.'
    },
    header: {
      title: 'Faire Preise',
      subtitle: 'Für hochwertige Dienstleistungen.\nWäscherei und Schneiderei in einem.'
    },
    categories: {
      title: 'Kategorien',
      description: 'Wähle die Kategorie, die zu deinem Kleidungsstück passt.'
    },
    perKilo: {
      title: 'Pro Kilo',
      description: 'Waschen & Bügeln pro Kilo',
      note: 'Beinhaltet nur folgende Artikel: Badetuch, Geschirr- und Küchenhandtuch, Unterhemd, Unterwäsche, Socken, Strumpfhose, Pyjama, Jeanshosen, T-Shirt, Polo Shirt, Trainingsjacke und Trainingshose.',
      price: 'Ab CHF 7.90/kg'
    },
    perPiece: {
      title: 'Pro Stück',
      description: 'Waschen & Bügeln pro Stück',
      note: 'Alle anderen Artikel werden pro Stück berechnet.',
      price: 'Ab CHF 6.90/Stück'
    },
    sortiment: {
      link: 'Sortiment',
      showAll: 'Komplettes Sortiment anzeigen',
      findPrices: 'Alle Preise für die Artikel und ihre Services findest du im'
    },
    sections: {
      mostOrdered: 'Am meisten bestellt',
      men: 'Herren',
      women: 'Damen',
      tailoring: 'Schneiderei'
    },
    pricePrefix: 'ab',
    priceHidden: 'Preis Reinigung/Wäscherei',
    overview: {
      title: "Unsere attraktiven Preise für Sie",
      description: "Hier findest du eine Übersicht über unsere Preise für professionelle chemische Reinigung, Wäscherei und Schneiderei – zuverlässig und schnell in und um Zürich.",
      findPrices: "Alle Preise für die Artikel und ihre Services findest du im"
    }
  },
  auth: {
    modal: {
      login: {
        title: 'Anmelden',
        email: 'E-Mail',
        password: 'Passwort',
        submit: 'ANMELDEN',
        submitting: 'ANMELDEN...',
        forgotPassword: 'Passwort vergessen?',
        noAccount: 'Noch kein Konto?',
        register: 'Registrieren',
        emailPlaceholder: 'E-Mail-Adresse eingeben',
        passwordPlaceholder: 'Passwort eingeben',
        resetPassword: 'PASSWORT WIEDERHERSTELLEN',
        errors: {
          invalidCredentials: 'Ungültige E-Mail oder Passwort.',
          serverError: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
          emailNotFound: 'E-Mail nicht gefunden. Registriere dich, um darauf zugreifen zu können',
          passwordMismatch: 'Passwort und E-Mail stimmen nicht überein',
          generalError: 'Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns'
        },
        validation: {
          required: 'Erforderlich',
          invalidEmail: 'Nicht gültige E-Mail-Adresse'
        }
      },
      register: {
        title: 'Registrieren',
        personalData: 'Persönliche Daten',
        company: 'Firmenname',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail *',
        password: 'Passwort',
        confirmPassword: 'Passwort wiederholen',
        gender: {
          male: 'männl.',
          female: 'weibl.'
        },
        address: {
          title: 'Adresse',
          pickupLocation: 'Ort der Abholung (z.B zu Hause, im Büro ...)',
          street: 'Strasse und Hausnummer',
          zipCity: 'PLZ/Ort *',
          phone: 'Telefon',
          notes: 'Anmerkungen',
          hint: 'Bitte nicht vergessen, in der Anmerkungen anzugeben: Treppe, Etage, Innenbereich und andere für die Abholung nützliche Informationen!'
        },
        terms: {
          text: 'Mit Deiner Registrierung akzeptierst Du die',
          agb: 'AGB',
          forServices: 'für die wös.ch Dienstleistungen.'
        },
        buttons: {
          submit: 'REGISTRIEREN',
          submitting: 'REGISTRIEREN...',
          cancel: 'ABBRECHEN'
        },
        errors: {
          emailExists: 'E-Mail existiert bereits',
          generalError: 'Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns'
        },
        locationName: 'Ort der Abholung (z.B zu Hause, im Büro ...)',
        street: 'Strasse und Hausnummer',
        city: 'PLZ/Ort *',
        phone: 'Telefon',
        note: 'Anmerkungen',
        passwordCheck: 'Passwort wiederholen',
        hint: 'Bitte nicht vergessen, in der Anmerkungen anzugeben: Treppe, Etage, Innenbereich und andere für die Abholung nützliche Informationen!',
        validation: {
          required: "{{field}} ist erforderlich",
          email: "Nicht gültige E-Mail-Adresse",
          passwordLength: "Passwort Mindestlänge 5 Zeichen",
          passwordMatch: "Die eingegebenen Passwörter stimmen nicht überein",
          gender: "Geschlecht ist erforderlich",
          locationName: "Abholort ist erforderlich", 
          street: "Strasse und Hausnummer ist erforderlich",
          phone: "Telefon ist erforderlich",
          city: "PLZ/Ort ist erforderlich"
        }
      },
      forgotPassword: {
        title: 'Passwort vergessen',
        email: 'E-Mail',
        submit: 'SENDEN',
        back: 'Zurück zum Login'
      },
      validation: {
        required: 'Dieses Feld ist erforderlich',
        invalidEmail: 'Ungültige E-Mail-Adresse',
        passwordMismatch: 'Die Passwörter stimmen nicht überein',
        minLength: 'Mindestens {{count}} Zeichen',
        invalidPhone: 'Ungültige Telefonnummer',
        invalidCity: 'Ungültige Stadt',
        passwordRequired: 'Passwort ist erforderlich',
        passwordMinLength: 'Das Passwort muss mindestens {{count}} Zeichen lang sein',
        passwordsMatch: 'Die Passwörter müssen übereinstimmen',
        emailRequired: 'E-Mail ist erforderlich',
        firstNameRequired: 'Vorname ist erforderlich',
        lastNameRequired: 'Nachname ist erforderlich',
        phoneRequired: 'Telefonnummer ist erforderlich',
        streetRequired: 'Strasse ist erforderlich',
        cityRequired: 'Stadt ist erforderlich',
        locationNameRequired: 'Abholort ist erforderlich'
      },
      wrapper: {
        title: 'Anmelden oder Registrieren',
        description: 'Melde dich an oder registriere dich, um fortzufahren.',
        login: 'ANMELDEN',
        register: 'REGISTRIEREN',
        or: 'oder',
        notRegistered: 'Noch nicht registriert ?',
        registerBonus: {
          line1: 'Registriere dich und',
          line2: 'erhalte deine CHF 10.00',
          line3: 'Willkommensbonus !'
        }
      },
      resetPassword: {
        title: 'Passwort zurücksetzen',
        checkingEmail: 'E-Mail prüfen...',
        errors: {
          emailNotFound: 'E-Mail nicht gefunden.',
          passwordMismatch: 'Passwort und E-Mail stimmen nicht überein'
        },
        buttons: {
          cancel: 'ABBRECHEN',
          submit: 'SENDEN',
          submitting: 'SENDEN...'
        }
      },
      newPassword: {
        title: "Neues Passwort eingeben",
        password: "Neues Passwort",
        passwordCheck: "Passwort wiederholen",
        buttons: {
          cancel: "ABBRECHEN",
          confirm: "BESTÄTIGEN",
          confirming: "BESTÄTIGEN..."
        },
        validation: {
          passwordRequired: "Passwort ist erforderlich",
          passwordMinLength: "Passwort Mindestlänge 5 Zeichen",
          passwordCheckRequired: "Wiederholtes Passwort ist erforderlich",
          passwordMismatch: "Die eingegebenen Passwörter stimmen nicht überein"
        },
        errors: {
          emailNotFound: "E-Mail nicht gefunden. Registriere dich, um darauf zugreifen zu können",
          passwordMismatch: "Passwort und E-Mail stimmen nicht überein",
          generalError: "Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns"
        }
      }
    }
  },
  processingTime: {
    days: '14 Tage',
    hours: '48 Stunden'
  },
  address: {
    validation: {
      locationRequired: 'Abholort ist erforderlich',
      streetRequired: 'Strasse und Hausnummer ist erforderlich',
      cityRequired: 'Ort ist erforderlich',
      phoneRequired: 'Telefon ist erforderlich'
    },
    form: {
      save: {
        submit: 'SPEICHERN',
        submitting: 'SPEICHERN...'
      },
      defaults: {
        billing: 'Dies ist die Standard-Rechnungsadresse',
        pickup: 'Dies ist die Standard-Abholadresse',
        delivery: 'Dies ist die voreingestellte Lieferadresse'
      }
    },
    actions: {
      delete: 'LÖSCHEN',
      edit: 'BEARBEITEN'
    }
  },
  userProfile: {
    personalData: 'Persönliche Daten',
    form: {
      company: 'Firmenname',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      password: 'Passwort',
      passwordCheck: 'Passwort wiederholen',
      gender: {
        male: 'männl.',
        female: 'weibl.'
      },
      save: {
        submit: 'SPEICHERN',
        submitting: 'SPEICHERN...'
      }
    },
    validation: {
      emailRequired: 'Die E-Mail ist erforderlich',
      invalidEmail: 'Nicht gültige E-Mail-Adresse',
      emailExists: 'Email schon registriert!',
      generalError: 'Etwas ist schief gelaufen, bitte versuche es später oder kontaktiere uns',
      firstNameRequired: 'Der Name ist erforderlich',
      lastNameRequired: 'Der Nachname ist erforderlich',
      passwordRequired: 'Passwort ist erforderlich',
      passwordMinLength: 'Passwort Mindestlänge 5 Zeichen',
      passwordCheckRequired: 'Wiederholtes Passwort ist erforderlich',
      passwordMismatch: 'Die eingegebenen Passwörter stimmen nicht überein',
      genderRequired: 'Geschlecht ist erforderlich'
    },
    tabs: {
      profile: 'PROFIL',
      addresses: 'ADRESSEN', 
      orders: 'AUFTRÄGE',
      bonuses: 'MEINE BONI'
    }
  },
  order: {
    orderTime: 'Bestellzeitpunkt',
    orderNumber: 'Auftragsnummer',
    status: 'Status',
    showDetails: 'Details anzeigen',
    totals: {
      orderAmount: 'Auftragssumme',
      shippingCosts: 'Zustellungskosten',
      shippingNote: 'Abholung und Lieferung für Aufträge über 30.00 CHF gratis',
      totalWithShipping: 'Summe inkl. Zustellungskosten',
      usedCredits: 'Verwendetes Guthaben und Bons',
      amountToPay: 'Zu zahlender Betrag'
    },
    
    details: {
      backToOrders: 'Zurück zu Bestellungen',
      details: 'Details',
      orderNumber: 'Bestellung Nr.:',
      date: 'Datum:',
      total: 'Total:',
      status: 'Status:',
      giftCard: 'Gift Card:',
      welcomeCode: 'Welcome-Code:',
      bonus: 'Bonus:',
      customer: {
        title: 'Kunde',
        fullName: 'Vor- und Nachname:',
        email: 'E-Mail:',
        billingLocation: 'Ort der Rechnungsadresse:',
        street: 'Strasse:',
        city: 'Ort:',
        phone: 'Telefon:',
        notes: 'Anmerkungen:'
      },
      pickup: {
        title: 'Abholung',
        dropOffInStore: 'Im Laden vorbeibringen',
        location: 'Ort der Abholung:',
        dateAndTime: 'Datum und Uhrzeit:'
      },
      delivery: {
        title: 'Lieferung',
        pickupInStore: 'Im Laden abholen',
        location: 'Ort der Lieferung:',
        dateAndTime: 'Datum und Uhrzeit:'
      },
      orderDetails: 'Bestellungsdetails'
    }
  },
  pagination: {
    previous: 'Bisherige',
    next: 'Nächste'
  },
  bonus: {
    orderTime: 'Bestellzeitpunkt',
    code: 'Code',
    status: 'Status',
    expiryDate: 'Ablaufdatum',
    unlimited: 'Unbegrenzt',
    balance: {
      total: 'Guthaben',
      remaining: 'Restguthaben'
    },
    message: {
      title: 'Persönliche Nachricht'
    },
    welcome: {
      title: "Willkommensbonus",
      description: "Willkommensbonus für Registrierung"
    }
  },
  messages: {
    contact: {
      subject: "WÖS.CH - New Message from contact form"
    }
  },
  faq: {
    meta: {
      title: "FAQ – Häufig gestellte Fragen | WÖS.CH",
      description: "Häufig gestellte Fragen im Zusammenhang mit WÖS.CH – vielleicht findest Du hier bereits wonach Du suchst."
    },
    pageTitle: "faq – häufig gestellte fragen",
    sections: {
      general: "I allgemeines",
      beforePickup: "Vor der ersten abholung",
      pricesAndBilling: "Preise & abrechnung",
      service: "Der service von wös.ch",
      washingAndDrycleaning: "Gewöhnliches waschen und trockenreinigung",
      accountIssues: "Probleme mit dem konto",
      customerService: {
        title: "Kundendienst: Bitte schreiben Sie uns an info@woes.ch",
        contact: "Haben Sie Fragen, Anregungen, Feedback zur Qualität oder zum Service, möchten Sie uns ein Kompliment machen oder einfach mit uns sprechen? Sie erreichen uns unter info@woes.ch."
      }
    },
    questions: {
      whatIsWoesch: {
        question: "Was bietet wös.ch an?",
        answer: "wös.ch ist eine einfache, praktische und kostengünstige Lösung für den Wäscherei- und Schneiderei-Service: Bestellungen rund um die Uhr, Abholungen und Lieferungen an sechs Tagen die Woche und 12 Stunden am Tag, höchste Qualität und 100% umweltfreundlich."
      },
      processingTime: {
        question: "Bearbeitungszeit der bestellung",
        answer: "Wir garantieren eine Bearbeitungszeit von 24 Stunden bei gewöhnlichen Wäscherei- und Bügelarbeiten, 48 Stunden bei speziellen Arbeiten (Waschen von empfindlichen Textilien und Schneiderei-Service usw.) und 14 Tagen bei aufwendigeren Dienstleistungen (Reinigung von Leder, Teppichen, Vorhängen usw.). Falls Ihre Bestellung Textilien oder Gegenstände mit unterschiedlicher Bearbeitungszeit umfasst, können Sie zwischen einer Sammellieferung oder zwei separaten Lieferungen wählen. Sie haben die freie Wahl. Bei Bestellungen ab CHF 30.00 sind Abholung und Lieferung KOSTENLOS (gilt für jede einzelne Lieferung, falls Sie sich für separate Lieferungen entscheiden)."
      },
      howToRegister: {
        question: "Wie kann ich mich registrieren?",
        answer: "Erstellen Sie einfach ein Konto über die Website www.wös.ch. Sie werden aufgefordert, Ihre E-Mail-Adresse, die Abhol- und Lieferadresse mit Angaben wie Etage, Treppe/Lift usw. sowie Ihre Handynummer einzugeben. Wir benötigen diese Informationen, um Ihnen einen bestmöglichen Service zu bieten."
      },
      isWoeschOfferedInYourArea: {
        question: "Wird wös.ch auch an meinem wohnort angeboten?",
        answer: "Um zu erfahren, ob Ihr Wohnort bedient wird, können Sie auf unserer Website einfach die Postleitzahl eingeben. Falls wir den Ort noch nicht abdecken, geben Sie unbedingt Ihre E-Mail-Adresse an, damit wir Sie benachrichtigen können, sobald der Service in Ihrer Region verfügbar ist."
      },
      howToPlaceAnOrder: {
        question: "Wie kann ich eine bestellung aufgeben?",
        answer: "Es ist ganz einfach: Nach der Registrierung geben Sie auf www.wös.ch Ihren Benutzernamen und Ihr Passwort ein und gelangen mit einem Klick auf «LOGIN» auf die Bestellseite. Dort können Sie alle gewünschten Dienstleistungen (Waschen, Bügeln und Schneiderei-Service) auswählen. Vergessen Sie nicht, für jedes einzelne Kleidungsstück Ihre Sonderwünsche anzugeben (Falten, Fleckenbehandlung usw.), und personalisieren Sie Ihre Bestellung falls gewünscht mit ausgewählten Duftstoffen. Wählen Sie den Tag und die Uhrzeit für Abholung und Lieferung und stellen Sie sicher, dass Sie im gewählten Zeitfenster zuhause sind oder teilen Sie uns mit, wo wir die Lieferung sicher deponieren können. Hinterlassen Sie nach ausgeführtem Auftrag einen Kommentar oder ein Feedback. Sie helfen uns damit, unseren Service stets zu verbessern."
      },
      howToChangePickupAndDeliveryTime: {
        question: "Wie kann ich tag und uhrzeit der abholung und lieferung ändern?",
        answer: "Haben sich Ihre Pläne geändert? Kein Problem! Nach der Auftragserteilung können Sie uns bis 12 Stunden vor der geplanten Abholung oder Lieferung eine E-Mail an info@woes.ch senden mit Ihrem neuen Wunschtermin."
      },
      canYouUseTheServiceEveryWeekAtTheSameTime: {
        question: "Kann ich den service von wös.ch jede woche am selben tag und zur gleichen uhrzeit nutzen?",
        answer: "Ja, wir bieten auch Abonnemente an. Mit unseren Prepaid-Tarifen in Höhe von CHF 100.00, CHF 150.00, CHF 200.00 oder CHF 250.00 können Sie den Auftrag wöchentlich, alle zwei Wochen, alle drei Wochen oder alle vier Wochen ausführen lassen – zu bestmöglichen Konditionen. Wählen Sie einfach die gewünschte Frequenz und den Wunschtag für Abholung und Lieferung. Einfacher geht nicht!"
      },
      whatHappensIfTheWoeschCourierIsLate: {
        question: "Was geschieht, wenn sich der wös.ch-kurier verspätet?",
        answer: "Wir tun unser Bestes, um das vereinbarte Zeitfenster einzuhalten. Aufgrund externer Faktoren können wir uns aber trotzdem einmal verspäten. Es ist uns bewusst, dass Sie noch andere Verpflichtungen haben. Falls wir uns verspäten und Sie nicht auf uns warten können, schreiben Sie uns bitte eine E-Mail an info@woes.ch. Wir werden Ihre Bestellung gerne kostenlos neu terminieren."
      },
      whatToDoIfYouHaveTechnicalDifficulties: {
        question: "Was kann ich tun, wenn ich schwierigkeiten mit der webseite habe?",
        answer: "Unsere Website www.wös.ch wurde auch für mobile Endgeräte optimiert. Unsere Programmierer bemühen sich, sie laufend zu verbessern. Falls Sie trotzdem technische Schwierigkeiten haben, melden Sie dies bitte an info@woes.ch."
      },
      howToContactWoesch: {
        question: "Wie kann ich wös.ch kontaktieren?",
        answer: "Haben Sie Fragen, Anregungen, Feedback zur Qualität oder zum Service, möchten Sie ein Lob aussprechen oder einfach mit uns reden? Sie können uns jederzeit an info@woes.ch schreiben oder anrufen. Wir stehen gerne für alle Fragen rund um wös.ch zur Verfügung."
      },
      doYouNeedToSortYourClothes: {
        question: "Muss ich meine kleider sortieren?",
        answer: "Ja. Aber keine Sorgen: es ist ganz einfach! Der wös.ch Kurier bringt passende Beutel mit, um Ihre Kleidungsstücke oder Gegenstände für die Textilreinigung, das Waschen und Bügeln oder den Schneiderei-Service abzuholen. Es reicht, wenn Sie die Sachen entsprechend trennen, um uns das Sortieren zu erleichtern. Wir nehmen keine persönlichen Gegenstände entgegen und übernehmen keine Haftung für Uhren, Schmuck oder andere Gegenstände, die während der Reinigung verlorengehen."
      },
      canYouSpecifySpecialRequests: {
        question: "Kann ich sonderwünsche angeben?",
        answer: "Natürlich! Sie können für jedes einzelne Kleidungsstück oder für mehrere Kleidungsstücke desselben Typs Ihre Präferenzen angeben. Nachdem Sie das Kleidungsstück oder den Gegenstand ausgewählt haben, das gereinigt oder repariert werden soll, können Sie in den entsprechenden Bereichen Zusatzdienste wie Bügeln, Falten oder Fleckenbehandlung hinzufügen. Diese Sonderwünsche sind KOSTENLOS und im Bestellpreis inbegriffen. Sie können für Kleidungsstücke oder Gegenstände, die Sie bei uns reinigen lassen, auch Schneiderarbeiten in Auftrag geben. Dabei gilt unsere Preisliste für den Schneiderei-Service. Alle anderen Wünsche können Sie im Feld «Anmerkung» eintragen. Wir wissen, wie viel Ihnen an Ihren Kleidungstücken, Accessoires und persönlichen Gegenstände liegt. Deshalb tun wir unser Bestes, um Ihre Sonderwünsche zu erfüllen."
      },
      canThePickupOrDeliveryTakePlaceIfYouAreAbsent: {
        question: "Kann die abholung oder lierferung auch stattfinden, wenn ich abwesend bin?",
        answer: "Ja, das ist möglich. Bitte teilen Sie uns hierzu bei der Bestellung die entsprechenden Anweisungen mit. Im letzten Schritt können Sie angeben, ob der wös.ch-Mitarbeiter Ihre Kleidung im Eingang, an der Rezeption usw. hinterlassen darf, wenn Sie am gewählten Tag und zur gewählten Zeit nicht zuhause oder an der angegebenen Adresse sind."
      },
      howToPay: {
        question: "Wie kann ich bezahlen? Welche zahlungsmöglichkeiten gibt es?",
        answer: "Die Bezahlung erfolgt sicher via Kreditkarte, PayPal und PostFinance. wös.ch setzt auf die wichtigsten Anbieter im Bereich der Zahlungssysteme. Nach dem Zahlungseingang erhalten Sie umgehend eine Bestellbestätigung mit allen Details. Haben Sie Fragen zu unseren Preisen? Sie können sich jederzeit mit unserem Kundendienst in Verbindung setzen via info@woes.ch."
      },
      isThereAMinimumOrderValue: {
        question: "Gibt es einen mindestbestellwert?",
        answer: "Bei uns gibt es keinen Mindestbestellwert."
      },
      isThereAPickupOrDeliveryFee: {
        question: "Gibt es eine abhol- oder liefergebühr?",
        answer: "Abholung und Lieferung sind bei Bestellungen ab CHF 30.00 KOSTENLOS. Bei Bestellungen unter CHF CHF 30.00 verrechnen wir CHF 7.50 für die Abholung und CHF 7.50 für die Lieferung."
      },
      howToRedeemARabattCoupon: {
        question: "Wie kann ich einen rabattbon einlösen?",
        answer: "Rabattbons wie der Willkommensbonus können Sie bei der Registrierung einlösen, und solche aus Sonderaktionen oder Einladungen Ihrer Freunde zu einem beliebigen Zeitpunkt. Sie finden alle Rabattbons in Ihrem Konto im Bereich MEINE BONI. Vergessen Sie nicht, Ihre Lieben, Freunde und Kollegen einzuladen, wös.ch auszuprobieren. Es lohnt sich! Wenn Sie uns weiterempfehlen, erhalten Ihre Freunde als auch Sie einen Bonus von CHF 5.00, um unseren Service zu testen und bei ihrer ersten Bestellung erhalten Sie als Dank einen Willkommensbonus im Wert von CHF 10.00! Es ist ganz einfach. So haben Sie jederzeit Rabattboni für Ihren Lieblingsservice."
      },
      howToUseAGiftCard: {
        question: "Wie kann ich einen geschenkgutschein verwenden?",
        answer: "Jemand hat Ihnen mit einem Gutschein von wös.ch freie Zeit geschenkt? Grossartig! Um das Guthaben zu nutzen, geben Sie bei der Zahlung im entsprechenden Abschnitt einfach den Gutscheincode ein. Das Guthaben wird sogleich abgezogen und hat kein Ablaufdatum."
      },
      howMuchDoesTheWoeschServiceCost: {
        question: "Wie viel kostet der service von wös.ch?",
        answer: "wös.ch bietet volle Transparenz und ein hervorragendes Preis-Leistungs-Verhältnis mit sehr günstigen Konditionen. Auf wös.ch finden Sie alle unsere Preise, damit Sie genau wissen, wie viel Sie für den gewünschten Service bezahlen und Ihre Ausgaben stets im Griff haben."
      },
      whatWashingProductsAreUsed: {
        question: "Welche waschmittel werden verwendet?",
        answer: "Wir verwenden umweltfreundliche und biologisch abbaubare Waschmittel, denn es ist uns wichtig, den Planeten, Ihre Gesundheit und Ihre Haut zu schützen!"
      },
      isTheWoeschServiceEcological: {
        question: "Ist der service von wös.ch ökologisch?",
        answer: "Wir setzen auf erneuerbare Energie, biologisch abbaubare und schonende Waschmittel und recyclingfähige Verpackungen. All dies, um die Umwelt zu schonen. Das macht uns aus und gehört zu unseren Werten: Wir denken und handeln nachhaltig und ökologisch, weil wir ein positives Zeichen setzen wollen!"
      },
      areThereMaterialsYouCannotHandle: {
        question: "Gibt es materialien, die sie nicht behandeln können?",
        answer: "In unserer spezialisierten Wäscherei reinigen und waschen wir alle Arten von Textilien, einschliesslich Leder, Nubuk, Wildleder, Kaschmir sowie Öko-Leder. Wir sind auch ausgerüstet, um Teppiche, Vorhänge und andere Heimtextilien zu behandeln sowie Taschen zu reparieren und reinigen. Wir übernehmen normale Fleckenbehandlungen. Vergessen Sie nicht, uns bei der Bestellung mitzuteilen, falls Sie diese Behandlung wünschen. Wenn der Fleck bereits tief in das Gewebe eingedrungen ist, kann es schwierig oder gar unmöglich sein, ihn zu entfernen, da die wiederholte Behandlung zu Farbverlust oder sichtbaren Schäden wie raue oder ausgefranste Stellen führen würde. Es gibt zudem einige besondere synthetische Materialien, die wir nicht behandeln können. Falls unser Team ein Kleidungsstück oder einen Gegenstand nicht waschen oder reparieren kann, melden wir uns bei Ihnen via E-Mail oder telefonisch. Selbstverständlich schreiben wir Ihnen den Betrag gut, den Sie für den entsprechenden Service bezahlt haben."
      },
      whatHappensIfAnAnnoyingStainCannotBeRemoved: {
        question: "Was passiert, wenn ein lästiger fleck nicht entfernt werden kann?",
        answer: "Wir tun unser Bestes, um Flecken zu entfernen, aber einige sind leider dauerhaft. Bei Flecken, die bereits tief in das Gewebe eingedrungen sind, führen wiederholte Behandlungen zu Farbverlust oder sichtbaren Schäden wie raue oder ausgefranste Stellen. Falls unser Team ein Kleidungsstück oder einen Gegenstand nicht waschen oder reinigen kann, melden wir uns bei Ihnen via E-Mail oder telefonisch."
      },
      liabilityForButtonsAndAccessories: {
        question: "Haftung für knöpfe und accessoires",
        answer: "Wie in der Branche üblich haften wir nicht für Knöpfe und Accessoires, die uns bei der Reinigung oder Behandlung von Textilien anvertraut werden. Bitte informieren Sie uns, falls ein Knopf nicht mehr gut hält, oder entfernen Sie diesen vor der Reinigung, um Unannehmlichkeiten oder mögliche Schäden zu vermeiden."
      },
      whatIsRegularWashing: {
        question: "Was heisst gewöhnliches waschen?",
        answer: "Gewöhnliches Waschen ist die ideale Reinigungsart für alle Kleidungsstücke und erfolgt mit umweltfreundlichen Waschmitteln. Wenn Sie den Standardservice ohne Sonderwünsche wählen, werden Ihre Kleidungsstücke gewaschen, getrocknet und an Kleiderbügel hängend geliefert. Wenn wir Ihre Kleidungsstücke falten sollen, können Sie dies bei den Sonderwünschen angeben."
      },
      whatIsDryCleaning: {
        question: "Was ist trockenreinigung, und wie weiss ich, ob ein kleidungsstück trocken gereinigt werden sollte?",
        answer: "Gewisse Kleidungsstücke sind aus empfindlichen Materialien (Seide, Wolle, Kaschmir usw.) und sollten nur trocken gereinigt werden. Bei wös.ch verwenden wir dafür biologisch abbaubare Mittel, welche die Umwelt schonen und einen umweltverträglichen Waschservice garantieren."
      },
      doYouNeedToSpecifyWhenSortingClothes: {
        question: "Muss ich bei der bestellung angeben, ob die kleider gewaschen oder trocken gereinigt werden sollen?",
        answer: "Nein. wös.ch wählt für Ihre Kleidungsstücke jeweils die beste Reinigungsmethode. Wir garantieren eine Reinigung gemäss Anweisung auf dem Etikett und eine optimale Pflege der Stoffe. Wenn auf dem Etikett zum Beispiel das Symbol für die Trockenreinigung steht, behandeln wir das Kleidungsstück entsprechend. Wenn Sie besondere Wünsche haben, tragen Sie diese bitte ins Feld «Anmerkung» ein, nachdem Sie das Kleidungsstück ausgewählt haben. Wir werden diese befolgen und Sie informieren, falls es Schwierigkeiten geben sollte. Für spezifische Fragen können Sie sich gerne an unseren Kundendienst wenden, der Sie mit einer Fachperson verbindet."
      },
      whereCanYouFindYourOrders: {
        question: "Wo finde ich meine bestellungen?",
        answer: "Möchten Sie eine alte Bestellung einsehen? Gehen Sie mit Ihrem Computer oder Smartphone auf wös.ch und melden Sie sich an. Klicken Sie auf \"Aufträge\", um alle bisherigen Transaktionen mit Details zu den einzelnen Bestellungen zu sehen."
      },
      whatHappensIfYouForgetToPickUp: {
        question: "Was geschieht, wenn ich die abholung vergesse?",
        answer: "Es ist uns bewusst, dass das Leben manchmal hektisch ist und sich Pläne plötzlich ändern können. Wenn Sie bereits im Voraus wissen, dass Sie zum vereinbarten Termin nicht an der angegebenen Adresse sein können, informieren Sie uns bitte mindestens 12 Stunden vor der gewählten Abhol- oder Lieferzeit via info@woes.ch. Wenn Sie uns nicht innerhalb dieser Frist informieren, berechnen wir zur Deckung unserer Kurierkosten eine Gebühr von CHF 15.00."
      },
      whatToDoIfYouHaveProblemsWithYourAccountOrPassword: {
        question: "Was muss ich tun, wenn ich probleme mit meinem konto oder mein passwort vergessen habe?",
        answer: "Haben Sie Ihr Passwort vergessen? Können Sie sich nicht anmelden? Versuchen Sie Ihr Passwort zurückzusetzen. Sollte es trotzdem nicht klappen, senden Sie uns eine E-Mail an info@woes.ch. Eine Mitarbeiterin oder ein Mitarbeiter von uns wird sich umgehend mit Ihnen in Verbindung setzen."
      }
    }
  },
  status: {
    order: {
      unpaid: "Nicht bezahlt",
      received: "Empfangen",
      processing: "Wird bearbeitet",
      completed: "Abgeschlossen"
    },
    giftcard: {
      usable: "Verwendbar",
      used: "Verwendet",
      processing: "Wird bearbeitet",
      completed: "Abgeschlossen"
    }
  }
};
